<template>
    <!-- 兑换记录列表详情 -->
    <div class="order-show grey-bg">
        <!--头部开始-->
        <div class="show-header vertical-center space-between">
            <div class="header-tit" v-if="orderShow.status == 0">待发货</div>
            <div class="header-tit" v-else-if="orderShow.status == 1">已完成</div>
            <div class="header-tit" v-else-if="orderShow.status == -1">已关闭</div>
            <div class="header-tit" v-else-if="orderShow.status == 2">待收货</div>

            <img src="~assets/images/user/order-suc.png" v-if="orderShow.status == 1 || orderShow.status == -1" class="header-img" />
            <img src="~assets/images/user/order-await.png" v-else class="header-img"/>
        </div>
        <!--头部结束-->

        <!--地址显示开始-->
        <div class="address-box vertical-center" v-if="(orderShow.goods && orderShow.goods.type == 'entity') || orderShow.flea">
            <img src="~assets/images/public/location-icon.png" class="location-icon"/>
            <div class="location-text-box">
                <div class="location-user vertical-center">
                    <span class="name white-space">{{ orderShow.contact_name }}</span>
                    <span class="tell">{{ orderShow.contact_mobile }}</span>
                </div>
                <div class="location-text white-space-2">{{ orderShow.address }}</div>
            </div>
        </div>
        <!--地址显示结束-->
        

        <!--商品信息开始-->
        <div class="commodity-wrap" v-if="orderShow.goods">
            <div class="commodity-contre vertical-end" @click="commodityLink">
                <img :src="orderShow.goods.cover_picture" class="commodity-img"/>
                <div class="commodity-info">
                    <div class="title">{{ orderShow.goods.title }}</div>
                    <div class="integral">{{orderShow.goods.credit}}积分</div>
                </div>
            </div>
            <div class="actually-box space-between">
                <span>商品兑换</span>
                <span>{{orderShow.goods.credit}}积分</span>
            </div>
        </div>
        <div class="commodity-wrap" v-if="orderShow.flea">
            <div class="commodity-contre vertical-end" @click="fleaLink">
                <img :src="orderShow.flea.picture[0]" class="commodity-img"/>
                <div class="commodity-info">
                    <div class="title">{{ orderShow.flea.title }}</div>
                    <div class="integral">{{orderShow.credit}}积分</div>
                </div>
            </div>
            <div class="actually-box space-between">
                <span>商品兑换</span>
                <span>{{orderShow.credit}}积分</span>
            </div>
            <div class="actually-box space-between">
                <span>运费</span>
                <span>{{orderShow.postage}}积分</span>
            </div>
        </div>
        <!--商品信息结束-->

        <div class="order-detail">
            <div class="detail-tit">订单信息</div>
            <div class="list">
                <div class="list-text">
                    订单编号
                    <text class="order-num">{{orderShow.no}}</text>
                </div>
                <div class="copy-btn" :data-clipboard-text="orderShow.no" @click="copy">复制</div>
            </div>
            <div class="list">
                <div class="list-text">
                    兑换时间
                    <text class="order-num">{{orderShow.created_at}}</text>
                </div>
            </div>
          <div class="list" v-if="orderShow.user_remark">
            <div class="list-text">
              订单备注
              <text class="order-num">{{orderShow.user_remark}}</text>
            </div>
          </div>
        </div>
      <!-- 链接信息 -->
      <div class="order-detail" v-if="orderShow.goods_item">
        <div class="detail-tit">链接信息</div>
        <div class="list">
          <div class="list-text">
            提取链接
            <text class="order-num" id="link-url">{{orderShow.goods_item.url}}</text>
          </div>
          <div class="copy-btn" :data-clipboard-text="orderShow.goods_item.url" @click="copy">复制</div>
        </div>
        <div class="list" v-if="orderShow.goods_item && orderShow.goods_item.code !='' && orderShow.goods_item.code != null">
          <div class="list-text">
            提取码
            <text class="order-num" id="link-code">{{orderShow.goods_item.code}}</text>
          </div>
        </div>
      </div>

        <!--订单底部按钮开始-->
        <div class="order-show-bot vertical-end safety-height" v-if="orderShow.goods && orderShow.goods.type == 'entity' && (orderShow.status != 0 && orderShow.status != -1)">
            <div class="bot-box vertical-center">
                <div class="bot-btn theme-bg affirm-btn" v-if="orderShow.status == 2" @click="takeShow" >确认收货</div>
                <div class="bot-btn logistics-btn" @click="logistics">查看物流</div>
            </div>
        </div>
        <!--订单底部按钮结束-->

      <!--确认收货提示开始-->
      <van-overlay :show="take_status" z-index="999">
        <div class="overlay-receiving-goods-con">
          <div class="overlay-receiving-goods">
            <div class="overlay-goods-head">
              <img class="overlay-goods-head-img" @click="take_status = false" src="~assets/images/user/overlay-cancel.png"/>
            </div>
            <div class="overlay-goods-body">
              <img class="overlay-goods-tip-img" src="~assets/images/user/overlay-tip.png"/>
              <div class="overlay-goods-tip-text">请收到货后，再确认收货！</div>
              <div class="overlay-btn">
                <div class="cancel" @click="take_status = false">取消</div>
                <div class="confirm" @click="takeConfirm">确认收货</div>
              </div>
            </div>
          </div>
        </div>
      </van-overlay>
      <!--确认收货提示结束-->

    </div>
</template>

<script>
import Clipboard from 'clipboard'
export default {
  name: "record",
  data(){
    return{
      orderShow: {},
      take_status: false, // 收货弹框状态
      type:''
    }
  },
  created() {
    if(this.$route.params.id){
      this.type = this.$route.query.type
      this.getShow(this.$route.params.id);
    }else {
      this.$router.replace({path: '/user/record'})
    }
  },
  methods: {
    // 获取兑换详情
    getShow(id){
      if(this.type == 'fleas'){
this.$http.get(this.$api.communityFleaOrder + '/' + id,true).then( res => {
        if(res.data.success){
          this.orderShow = res.data.data;
        }
      })
      }else{
this.$http.get(this.$api.OrdersShow + '/' + id,true).then( res => {
        if(res.data.success){
          this.orderShow = res.data.data;
        }
      })
      }
      
    },
    // 点击复制事件
    copy(){
      var clipboard = new Clipboard('.copy-btn');
      clipboard.on('success', e => {
        this.$toast('复制成功');
        // 释放内存
        clipboard.destroy()
      });
      clipboard.on('error', e => {
        // 不支持复制
        this.$toast('该浏览器不支持自动复制');
        // 释放内存
        clipboard.destroy()
      })
    },
    // 确认收货方法
    takeConfirm(){
      this.$http.post(this.$api.OrdersUpdate,{id: this.orderShow.id},true).then(res => {
        if(res.data.success){
          this.$toast('收货成功');
          this.take_status = false;
          this.getShow(this.$route.params.id);
        }
      })
    },
  //   显示确认收货弹框
    takeShow(){
      this.take_status = true;
    },
  //  查看物流
    logistics(){
      this.$router.push({path: `/order/record/logistics/${this.orderShow.id}`})
    },
  //  商品详情跳转
    commodityLink(){
      this.$router.push({path: `/malls/show/${this.orderShow.goods.uuid}`})
    },
     fleaLink(){
       this.$router.push({path: '/community/market-show',query: {id: this.orderShow.flea.id}})
    },
  }
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/user/record/show.scss";
//确认收货提示开始
#link-code{
  margin-left: 84/$r;
}
#link-url{
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 400/$r;
  line-height: 36/$r;
  position: absolute;
  top: 0;
}
.overlay-receiving-goods-con{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .overlay-receiving-goods{
    width: 600/$r;
    padding-bottom: 62/$r;
    background: #FFFFFF;
    border-radius: 20/$r;
    .overlay-goods-head{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 600/$r;
      height: 127/$r;
      padding-right: 40/$r;
      box-sizing:border-box;
      background: #FF9E73;
      border-radius: 20/$r 20/$r 0/$r 0/$r;

      .overlay-goods-head-img{
        width: 35/$r;
        height: 34/$r;
      }
    }
    .overlay-goods-body{
      display: flex;
      flex-direction: column;
      align-items: center;
      .overlay-goods-tip-img{
        width: 97/$r;
        height: 96/$r;
        margin: 43/$r 0 45/$r 0;
      }
      .overlay-goods-tip-text{
        font-size: 28/$r;
        font-weight: 500;
        color: #333333;
        line-height: 36/$r;
      }
      .overlay-btn{
        display: flex;
        margin-top: 78/$r;
        font-size: 28/$r;
        font-family: Source Han Sans CN;
        font-weight: 300;
        line-height: 74/$r;
        color: #999999;
        .cancel{
          width: 240/$r;
          height: 74/$r;
          margin-right: 42/$r;
          background: #FFFFFF;
          border: 1px solid #B5B5B5;
          border-radius: 37/$r;
          text-align: center;
        }
        .confirm{
          width: 240/$r;
          height: 74/$r;
          background: #FF6B29;
          border-radius: 37/$r;
          text-align: center;
          color: #FFFFFF;
        }
      }
    }
  }
}
//确认收货提示结束
</style>